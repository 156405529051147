html, body {
  width: 100%;
  height: 100%;
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #feffe6;
  padding: .2em;
}

.IoxJ0q_center {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.rqZnma_wrapper {
  position: relative;
}

.rqZnma_wrapper .rqZnma_canvas {
  width: 100%;
  height: calc(100% - 40px);
  position: absolute;
  top: 40px;
  left: 0;
  overflow: auto;
}

.rqZnma_wrapper .rqZnma_toolbar {
  z-index: 100;
  background: #fffc;
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding: 5px;
  position: absolute;
}

.rqZnma_wrapper .rqZnma_toolbar [class*="ant-badge-status-dot"] {
  width: 20px !important;
  height: 20px !important;
}

.rqZnma_wrapper .rqZnma_toolbar i {
  pointer-events: none;
  font-size: 20px;
}

.rqZnma_wrapper .rqZnma_toolbar .ant-typography {
  align-items: center;
  font-size: 14px;
  display: inline-flex;
}

.rqZnma_wrapper .rqZnma_toolbar button {
  position: relative;
  width: 32px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rqZnma_wrapper .rqZnma_toolbar button [class*="twitter-picker"] {
  z-index: 100;
  position: absolute;
  top: 100%;
}

._8bKpCa_wrapper {
  position: relative;
}

._8bKpCa_search {
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  display: flex;
}

._8bKpCa_search [class*="ant-input-group-wrapper"], ._8bKpCa_search [class*="ant-picker-range"] {
  max-width: 300px;
}

@media (width <= 768px) {
  ._8bKpCa_search {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
  }

  ._8bKpCa_search [class*="ant-input-group-wrapper"], ._8bKpCa_search [class*="ant-picker-range"] {
    width: 100%;
    max-width: 100%;
  }
}

.rPBmxq_form {
  border-radius: 15px;
  min-width: 320px;
  padding: 20px;
}

.rPBmxq_form h2 {
  text-align: center;
}

.rPBmxq_form button[class*="ant-btn-link"] {
  float: right;
}
/*# sourceMappingURL=index.1fc35145.css.map */
